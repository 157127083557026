import ServicesData from './../data/services-data.json';
import { initialImage, analysisImage, solutionImage, strategyImage, implementImage, testingImage, trainingImage, supportImage, cicdImage, documentationImage } from "../images";

import { buildingAboutAnimation, consultingAboutAnimation, estimateAboutAnimation, heroAboutAnimation, initialAnimation, analysis, animation } from "../animation";
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import { useParams, Link } from 'react-router-dom'

export default function Service() {
    const params = useParams();
    const { slug } = params;
    console.warn(slug)
    const data = ServicesData.find(item => item.slug === slug);
    const subServices = data.subServices;
    return (
        <>
            {/*=====================================*/}
            {/*=       Breadcrumb Area Start       =*/}
            {/*=====================================*/}
            <div className="breadcrum-area breadcrumb-banner">
                <div className="container">
                    <div
                        className="section-heading heading-left"
                        data-sal="slide-right"
                        data-sal-duration={1000}
                        data-sal-delay={300}
                    >
                        <h1 className="title h2">{data.Service}</h1>
                        <p className="text-justify">{data.info}</p>
                    </div>
                    <div
                        className="banner-thumbnail thumbnail-4"
                        data-sal="slide-up"
                        data-sal-duration={1000}
                        data-sal-delay={400}
                    >
                        <Player
                            autoplay
                            speed={1.5}
                            loop
                            src={consultingAboutAnimation}
                            style={{ height: "936px", width: "666px" }}
                        >
                            <Controls
                                visible={false}
                                buttons={[
                                    "play",
                                    "repeat",
                                    "frame",
                                    "debug",
                                    "snapshot",
                                    "background"
                                ]}
                            />
                        </Player>
                    </div>
                </div>
                <ul className="shape-group-8 list-unstyled">
                    <li
                        className="shape shape-1"
                        data-sal="slide-right"
                        data-sal-duration={500}
                        data-sal-delay={100}
                    >
                        <img src="../assets/media/others/bubble-9.png" alt="Bubble" />
                    </li>
                    <li
                        className="shape shape-2"
                        data-sal="slide-left"
                        data-sal-duration={500}
                        data-sal-delay={200}
                    >
                        <img src="../assets/media/others/bubble-22.png" alt="Bubble" />
                    </li>
                    <li
                        className="shape shape-3"
                        data-sal="slide-up"
                        data-sal-duration={500}
                        data-sal-delay={300}
                    >
                        <img src="../assets/media/others/line-4.png" alt="Line" />
                    </li>
                </ul>
            </div>
            {/*=====================================*/}
            {/*=       Work Process Area Start     =*/}
            {/*=====================================*/}
            <section className="section section-padding bg-color-light pb--70">
                <div className="container">
                    {/* <div className="section-heading mb--90">
                        <span className="subtitle">Process</span>
                        <h2 className="title">Our Process</h2>
                    </div> */}
                    {
                        subServices.map(subServices => {
                        return (
                            <div
                                className={ subServices.subid%2 ===0 ? "process-work": "process-work content-reverse" }
                                data-sal= { subServices.subid%2 ===0 ? "slide-right":"slide-left" }
                                data-sal-duration={1000}
                                data-sal-delay={100}
                            >
                                <div className="">
                                    <Player autoplay speed={1.5} loop src={animation} style={{ height: "500px", width: "500px"}} >  
                                        <Controls visible={false}
                                            buttons={[
                                                "play",
                                                "repeat",
                                                "frame",
                                                "debug",
                                                "snapshot",
                                                "background"
                                            ]}
                                        />
                                    </Player>
                                </div>
                                <div className="content" style={{padding:"0 0 0 100px"}}>
                                    {/* <span className="subtitle">{data.Service}</span> */}
                                    <h3 className="title">{subServices.name}</h3>
                                    <p className="text-justify mb-2" style={{width:"80%"}}>
                                        {subServices.p1}
                                    </p>
                                    <p className="text-justify mb-2" style={{width:"80%"}}>
                                        {subServices.p2}
                                    </p>
                                    <p className="text-justify mb-2" style={{width:"80%"}}>
                                        {subServices.p3}
                                    </p>
                                </div>
                            </div>
                        )}
                    )}                  
                </div>
                <ul className="shape-group-17 list-unstyled">
                    <li className="shape shape-1">
                        <img src="../assets/media/others/bubble-24.png" alt="Bubble" />
                    </li>
                    <li className="shape shape-2">
                        <img src="../assets/media/others/bubble-23.png" alt="Bubble" />
                    </li>
                    <li className="shape shape-3">
                        <img src="../assets/media/others/line-4.png" alt="Line" />
                    </li>
                    <li className="shape shape-4">
                        <img src="../assets/media/others/line-5.png" alt="Line" />
                    </li>
                    <li className="shape shape-5">
                        <img src="../assets/media/others/line-4.png" alt="Line" />
                    </li>
                    <li className="shape shape-6">
                        <img src="../assets/media/others/line-5.png" alt="Line" />
                    </li>
                </ul>
            </section>
        </>
    )
}