import ContactInfo from "./../data/contact-data.json";
import ServicesData from './../data/services-data.json';

export default function Footer() {
    const logoLight = window.location.origin+'/assets/media/logo.png'
    const phoneOne = "tel:"+ContactInfo.phone1
    const phoneTwo = "tel:"+ContactInfo.phone2
    const emailInfo = "mailto:"+ContactInfo["email-info"]
    const emailSupport = "mailto:"+ContactInfo["email-support"]
    const social = ContactInfo.social
    return(
        <>
            <footer className="footer-area">
                <div className="container">
                <div className="footer-top">
                    <div className="footer-social-link">
                    <ul className="list-unstyled">
                        <li>
                        <a
                            href={social[0].facebook}
                            data-sal="slide-up"
                            data-sal-duration={500}
                            data-sal-delay={100}
                        >
                            <i className="fab fa-facebook-f" />
                        </a>
                        </li>
                        <li>
                        <a
                            href={social[0].twitter}
                            data-sal="slide-up"
                            data-sal-duration={500}
                            data-sal-delay={200}
                        >
                            <i className="fab fa-twitter" />
                        </a>
                        </li>
                        <li>
                        <a
                            href={social[0].pintrest}
                            data-sal="slide-up"
                            data-sal-duration={500}
                            data-sal-delay={300}
                        >
                            <i className="fab fa-pinterest-p" />
                        </a>
                        </li>
                        <li>
                        <a
                            href={social[0].linkedin}
                            data-sal="slide-up"
                            data-sal-duration={500}
                            data-sal-delay={400}
                        >
                            <i className="fab fa-linkedin-in" />
                        </a>
                        </li>
                        <li>
                        <a
                            href={social[0].instagram}
                            data-sal="slide-up"
                            data-sal-duration={500}
                            data-sal-delay={500}
                        >
                            <i className="fab fa-instagram" />
                        </a>
                        </li>
                        <li>
                        <a
                            href={social[0].vimeo}
                            data-sal="slide-up"
                            data-sal-duration={500}
                            data-sal-delay={600}
                        >
                            <i className="fab fa-vimeo-v" />
                        </a>
                        </li>
                        <li>
                        <a
                            href={social[0].dribbble}
                            data-sal="slide-up"
                            data-sal-duration={500}
                            data-sal-delay={700}
                        >
                            <i className="fab fa-dribbble" />
                        </a>
                        </li>
                        <li>
                        <a
                            href={social[0].behance}
                            data-sal="slide-up"
                            data-sal-duration={500}
                            data-sal-delay={800}
                        >
                            <i className="fab fa-behance" />
                        </a>
                        </li>
                    </ul>
                    </div>
                </div>
                <div className="footer-main">
                    <div className="row">
                    <div
                        className="col-xl-6 col-lg-5"
                        data-sal="slide-right"
                        data-sal-duration={800}
                        data-sal-delay={100}
                    >
                        <div className="footer-widget border-end">
                            <h2 className="title">Get in touch!</h2>
                            <div className="row d-flex justify-content-md-center align-items-center">
                                <div className="col-xl-4 col-lg-4"><img src={logoLight}/></div>
                                <div className="col-xl-8 col-lg-8">
                                    <div className="footer-newsletter">
                                        
                                        <h5>GammaVerse Pvt. Ltd.</h5>
                                        <table>
                                            <tbody>
                                                <tr><td><p><i className="far fa-location-arrow"></i></p></td><td dangerouslySetInnerHTML={{__html: ContactInfo.address}}></td></tr>
                                                <tr><td><p><i className="fad fa-phone-alt"></i></p></td><td><p>{ContactInfo.phone1}, {ContactInfo.phone2}</p></td></tr>
                                                <tr><td><p><i className="fal fa-envelope-open-text"></i></p></td><td><p>{ContactInfo["email-info"]}</p></td></tr>
                                            </tbody>
                                        </table>
                                        <p> 
                                        </p>
                                        <form>
                                        <div className="input-group">
                                            <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Email address"
                                            />
                                            <button className="subscribe-btn" type="submit">
                                            Subscribe
                                            </button>
                                        </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-xl-6 col-lg-7"
                        data-sal="slide-left"
                        data-sal-duration={800}
                        data-sal-delay={100}
                    >
                        <div className="row">
                        <div className="col-sm-6">
                            <div className="footer-widget">
                            <h6 className="widget-title">IT Consulting & Services</h6>
                            <div className="footer-menu-link">
                                <ul className="list-unstyled">
                                {
                                    ServicesData.map(ServicesData => {
                                    const subLink = "/service/"+ServicesData.slug;
                                    return (
                                        <li key={ServicesData.slug}><a href={subLink}>{ServicesData.Service}</a></li>
                                    )}
                                )}
                                </ul>
                            </div>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="footer-widget">
                            <h6 className="widget-title">Resourses</h6>
                            <div className="footer-menu-link">
                                <ul className="list-unstyled">
                                <li>
                                    <a href="/blogs">Blog</a>
                                </li>
                                <li>
                                    <a href="/case-study">Case Studies</a>
                                </li>
                                <li>
                                    <a href="/portfolio">Portfolio</a>
                                </li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="footer-widget">
                            <h6 className="widget-title">Support</h6>
                            <div className="footer-menu-link">
                                <ul className="list-unstyled">
                                <li>
                                    <a href="/contact">Contact</a>
                                </li>
                                <li>
                                    <a href="/privacy-policy">Privacy Policy</a>
                                </li>
                                <li>
                                    <a href="/terms-of-use">Terms of Use</a>
                                </li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div
                    className="footer-bottom"
                    data-sal="slide-up"
                    data-sal-duration={500}
                    data-sal-delay={100}
                >
                    <div className="row">
                    <div className="col-md-6">
                        <div className="footer-copyright">
                        <span className="copyright-text">
                        Copyrights © {(new Date().getFullYear())}. All rights reserved by{" "}
                            <a href="https://GammaVerse.in/">GammaVerse Pvt. Ltd.</a>.
                        </span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="footer-bottom-link">
                        {/* <ul className="list-unstyled">
                            <li>
                            <a href="privacy-policy.html">Privacy Policy</a>
                            </li>
                            <li>
                            <a href="terms-of-use.html">Terms of Use</a>
                            </li>
                        </ul> */}
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </footer>
            {/*=====================================*/}
            {/*=       Offcanvas Menu Area       	=*/}
            {/*=====================================*/}
            <div
                className="offcanvas offcanvas-end header-offcanvasmenu"
                tabIndex={-1}
                id="offcanvasMenuRight"
            >
                <div className="offcanvas-header">
                <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                />
                </div>
                <div className="offcanvas-body">
                <form action="#" className="side-nav-search-form">
                    <div className="form-group">
                    <input
                        type="text"
                        className="search-field"
                        name="search-field"
                        placeholder="Search..."
                    />
                    <button className="side-nav-search-btn">
                        <i className="fas fa-search" />
                    </button>
                    </div>
                </form>
                <div className="row ">
                    {/* <div className="col-lg-5 col-xl-6">
                    <ul className="main-navigation list-unstyled">
                        <li>
                        <a href="/">Digital Agency</a>
                        </li>
                        <li>
                        <a href="index-2.html">Creative Agency</a>
                        </li>
                        <li>
                        <a href="index-3.html">Personal Portfolio</a>
                        </li>
                        <li>
                        <a href="index-4.html">Home Startup</a>
                        </li>
                        <li>
                        <a href="index-5.html">Corporate Agency</a>
                        </li>
                    </ul>
                    </div> */}
                    <div className="col-lg-12 col-xl-12">
                    <div className="contact-info-wrap">
                        <div className="contact-inner">
                        <address className="address">
                            <span className="title" >Contact Information</span>
                            <p dangerouslySetInnerHTML={{__html: ContactInfo.address}}> 
                            </p>
                        </address>
                        <address className="address">
                            <span className="title">We're Available 24/7. Call Now.</span>
                            <a className="tel" href={phoneOne}>
                            <i className="fas fa-phone" />
                            {ContactInfo.phone1}
                            </a>
                            <a className="tel" href={phoneTwo}>
                            <i className="fas fa-fax" />
                            {ContactInfo.phone2}
                            </a>
                        </address>
                        </div>
                        <div className="contact-inner">
                        <h5 className="title">Find us here</h5>
                        <div className="contact-social-share">
                            <ul className="social-share list-unstyled">
                            <li>
                                <a href="#">
                                <i className="fab fa-facebook-f" />
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                <i className="fab fa-twitter" />
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                <i className="fab fa-behance" />
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                <i className="fab fa-linkedin-in" />
                                </a>
                            </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </>

    );
}