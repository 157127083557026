import ContactInfo from "./../data/contact-data.json";
import { head } from "../animation";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
export default function Contact() {
    const logoLight = window.location.origin + '/assets/media/logo.png'
    const phoneOne = "tel:" + ContactInfo.phone1
    const phoneTwo = "tel:" + ContactInfo.phone2
    const emailInfo = "mailto:" + ContactInfo["email-info"]
    const emailSupport = "mailto:" + ContactInfo["email-support"]
    const social = ContactInfo.social
    return (
        <>
            {/*=====================================*/}
            {/*=       Breadcrumb Area Start       =*/}
            {/*=====================================*/}
            <div className="breadcrum-area">
                <div className="container">
                    <div className="breadcrumb">
                        <ul className="list-unstyled">
                            <li key="home">
                                <a href="/">Home</a>
                            </li>
                            <li key="contact" className="active">Contact</li>
                        </ul>
                        <h1 className="title h2">Contact</h1>
                    </div>
                </div>
                {/* <ul className="shape-group-8 list-unstyled">
                    <li key="shape-1"
                        className="shape shape-1"
                        data-sal="slide-right"
                        data-sal-duration={500}
                        data-sal-delay={100}
                    >
                        <img src="assets/media/others/bubble-9.png" alt="Bubble" />

                    </li>
                    <li key="shape-2"
                        className="shape shape-2"
                        data-sal="slide-left"
                        data-sal-duration={500}
                        data-sal-delay={200}
                    >
                        <Player 
                            autoplay
                            speed={1.5}
                            loop
                            src={head}
                            style={{ height: "500px", width: "500px" }}
                        >
                            <Controls
                                visible={false}
                                buttons={[
                                    "play",
                                    "repeat",
                                    "frame",
                                    "debug",
                                    "snapshot",
                                    "background"
                                ]}
                            />
                        </Player>
                    </li>
                    <li key="shape-3"
                        className="shape shape-3"
                        data-sal="slide-up"
                        data-sal-duration={500}
                        data-sal-delay={300}
                    >
                        <img src="assets/media/others/line-4.png" alt="Line" />
                    </li>
                </ul> */}
                <ul className="shape-group-8 list-unstyled">
                    <li key="shape-1" className="shape shape-1" data-sal="slide-right" data-sal-duration={500} data-sal-delay={100}>
                        <img src="assets/media/others/bubble-9.png" alt="Bubble" />
                    </li>
                    <li key="shape-conatct" className="shape shape-2" data-sal="slide-left" data-sal-duration={500} data-sal-delay={200}>
                        <Player
                            autoplay
                            speed={1.5}
                            loop
                            src={head}
                            style={{ height: "500px", width: "500px" }}
                        >
                            <Controls
                                visible={false}
                                buttons={["play", "repeat", "frame", "debug", "snapshot", "background"]}
                            />
                        </Player>
                    </li>
                    <li key="shape-3" className="shape shape-3" data-sal="slide-up" data-sal-duration={500} data-sal-delay={300}>
                        <img src="assets/media/others/line-4.png" alt="Line" />
                    </li>
                </ul>

            </div>
            {/*=====================================*/}
            {/*=       Contact  Area Start        =*/}
            {/*=====================================*/}
            <section className="section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-6">
                            <div className="contact-form-box shadow-box mb--30">
                                <h3 className="title">Get connect now</h3>
                                <form
                                    method="POST"
                                    action="#"
                                    className="axil-contact-form"
                                >
                                    <div className="form-group">
                                        <label>Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="contact-name"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="contact-email"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Company</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="contact-company"
                                        />
                                    </div>
                                    <div className="form-group mb--40">
                                        <label>How can we help you?</label>
                                        <textarea
                                            name="contact-message"
                                            id="contact-message"
                                            className="form-control textarea"
                                            cols={30}
                                            rows={4}
                                            defaultValue={""}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <button
                                            type="submit"
                                            className="axil-btn btn-fill-primary btn-fluid btn-primary"
                                            name="submit-btn"
                                        >
                                            Connect Now
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 offset-xl-1">
                            <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--150">
                                <h4 className="title">Phone</h4>
                                <p>Our customer care is open from Mon-Fri, 10:00 am to 6:00 pm</p>
                                <h4 className="phone-number">
                                    <a href={phoneOne}>{ContactInfo.phone1}</a>
                                    <a href={phoneTwo}>{ContactInfo.phone2}</a>
                                </h4>
                            </div>
                            <div className="contact-info mb--30">
                                <h4 className="title">Email</h4>
                                <p>
                                    Our support team will get back to in 48-h during standard business
                                    hours.
                                </p>
                                <h4 className="phone-number">
                                    <a href={emailInfo}>{ContactInfo["email-info"]}</a>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="list-unstyled shape-group-12">
                    <li className="shape shape-1">
                        <img src="assets/media/others/bubble-2.png" alt="Bubble" />
                    </li>
                    <li className="shape shape-2">
                        <img src="assets/media/others/bubble-1.png" alt="Bubble" />
                    </li>
                    <li className="shape shape-3">
                        <img src="assets/media/others/circle-3.png" alt="Circle" />
                    </li>
                </ul>
            </section>
            {/*=====================================*/}
            {/*=       Location  Area Start        =*/}
            {/*=====================================*/}
            {/* <section className="section section-padding bg-color-dark overflow-hidden">
                <div className="container">
                    <div className="section-heading heading-light-left">
                        <span className="subtitle">Find Us</span>
                        <h2 className="title">Our office</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-sm-6">
                            <div className="office-location">
                                <div className="thumbnail">
                                    <img src="assets/media/others/location-1.png" alt="Office" />
                                </div>
                                <div className="content">
                                    <h4 className="title">Virginia-HQ</h4>
                                    <p>435 Pouros Locks United States</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="office-location">
                                <div className="thumbnail">
                                    <img src="assets/media/others/location-2.png" alt="Office" />
                                </div>
                                <div className="content">
                                    <h4 className="title">Nevada</h4>
                                    <p>46 Watsica Creek Suite 071 United States</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="office-location">
                                <div className="thumbnail">
                                    <img src="assets/media/others/location-3.png" alt="Office" />
                                </div>
                                <div className="content">
                                    <h4 className="title">Columbia</h4>
                                    <p>7140 Wehner Tunnel Washington, D.C</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="office-location">
                                <div className="thumbnail">
                                    <img src="assets/media/others/location-4.png" alt="Office" />
                                </div>
                                <div className="content">
                                    <h4 className="title">New Mexico</h4>
                                    <p>10 Maggie Valleys United States</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="shape-group-11 list-unstyled">
                    <li className="shape shape-1">
                        <img src="assets/media/others/line-6.png" alt="line" />
                    </li>
                    <li className="shape shape-2">
                        <img src="assets/media/others/circle-3.png" alt="line" />
                    </li>
                </ul>
            </section> */}
        </>

    );
}