import { header } from "../animation";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
export default function Coming() {
    return (
        <>
            {/*=====================================*/}
            {/*=      Comming Soon Area Start      =*/}
            {/*=====================================*/}
            <section className="coming-soon-area onepage-screen-area">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xl-6 col-lg-8">
                            <div className="content">
                                <h2 className="title">Our new website is on its way</h2>
                                <p>Exciting times ahead! We're putting the finishing touches on a groundbreaking addition to our website. Stay tuned for an enhanced experience that's tailored just for you.</p>
                                <div className="countdown" />
                                <form>
                                    <div className="input-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Email address"
                                        />
                                        <button className="subscribe-btn" type="submit">
                                            Notify Me
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div
                                className="thumbnail"
                                data-sal="zoom-in"
                                data-sal-duration={500}
                                data-sal-delay={100}
                            >
                                <Player
                                    autoplay
                                    speed={1.5}
                                    loop
                                    src={header}
                                    style={{ height: "936px", width: "666px" }}
                                >
                                    <Controls
                                        visible={false}
                                        buttons={[
                                            "play",
                                            "repeat",
                                            "frame",
                                            "debug",
                                            "snapshot",
                                            "background"
                                        ]}
                                    />
                                </Player>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="shape-group-8 list-unstyled">
                    <li
                        className="shape shape-2"
                        data-sal="slide-left"
                        data-sal-duration={500}
                        data-sal-delay={200}
                    >
                        <img src="assets/media/others/bubble-28.png" alt="Bubble" />
                    </li>
                    <li
                        className="shape shape-3"
                        data-sal="slide-up"
                        data-sal-duration={500}
                        data-sal-delay={300}
                    >
                        <img src="assets/media/others/line-4.png" alt="Line" />
                    </li>
                </ul>
            </section>
        </>


    );
}