import { initialImage, analysisImage, solutionImage, strategyImage, implementImage, testingImage, trainingImage, supportImage, cicdImage, documentationImage } from "../images";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { buildingAboutAnimation, consultingAboutAnimation, estimateAboutAnimation, heroAboutAnimation, initialAnimation, analysis, animation } from "../animation";
export default function About() {
    return (
        <>
            {/*=====================================*/}
            {/*=       Breadcrumb Area Start       =*/}
            {/*=====================================*/}
            <div className="breadcrum-area breadcrumb-banner">
                <div className="container">
                    <div
                        className="section-heading heading-left"
                        data-sal="slide-right"
                        data-sal-duration={1000}
                        data-sal-delay={300}
                    >
                        <h1 className="title h2">One of the fastest growing agency</h1>
                        <p className="text-justify">At Gammaverse, we are driven by a passion for technology and a commitment to delivering exceptional IT consulting and services. As a newly established firm, we bring fresh perspectives, innovative solutions, and unwavering dedication to every client engagement.
                        </p>
                    </div>
                    <div
                        className="banner-thumbnail thumbnail-4"
                        data-sal="slide-up"
                        data-sal-duration={1000}
                        data-sal-delay={400}
                    >
                        <Player
                            autoplay
                            speed={1.5}
                            loop
                            src={heroAboutAnimation}
                            style={{ height: "936px", width: "666px" }}
                        >  
                            <Controls
                            visible={false}
                            buttons={[
                                "play",
                                "repeat",
                                "frame",
                                "debug",
                                "snapshot",
                                "background"
                            ]}
                            />
                        </Player>
                    </div>
                </div>
                <ul className="shape-group-8 list-unstyled">
                    <li
                        className="shape shape-1"
                        data-sal="slide-right"
                        data-sal-duration={500}
                        data-sal-delay={100}
                    >
                        <img src="../assets/media/others/bubble-9.png" alt="Bubble" />
                    </li>
                    <li
                        className="shape shape-2"
                        data-sal="slide-left"
                        data-sal-duration={500}
                        data-sal-delay={200}
                    >
                        <img src="../assets/media/others/bubble-22.png" alt="Bubble" />
                    </li>
                    <li
                        className="shape shape-3"
                        data-sal="slide-up"
                        data-sal-duration={500}
                        data-sal-delay={300}
                    >
                        <img src="../assets/media/others/line-4.png" alt="Line" />
                    </li>
                </ul>
            </div>
            {/*=====================================*/}
            {/*=       About  Area Start        =*/}
            {/*=====================================*/}
            <section className="section section-padding case-study-featured-area">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 col-lg-6">
                            <div className="case-study-featured-thumb text-start">
                                {/* <img src="../assets/media/others/case-study-4.png" alt="travel" /> */}
                                <Player
                                    autoplay
                                    speed={1.5}
                                    loop
                                    src={buildingAboutAnimation}
                                   
                                >  
                                    <Controls
                                    visible={false}
                                    buttons={[
                                        "play",
                                        "repeat",
                                        "frame",
                                        "debug",
                                        "snapshot",
                                        "background"
                                    ]}
                                    />
                                </Player>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6">
                            <div className="case-study-featured">
                                <div className="section-heading heading-left">
                                    <span className="subtitle">Our Mission</span>
                                    <h2 className="title">Building software for world changers</h2>
                                    <p className="text-justify">Our mission is to empower businesses of all sizes to harness the full potential of technology. We strive to be the trusted partner that organizations rely on to navigate the complexities of the digital landscape, optimize their IT infrastructure, and achieve their strategic objectives.</p>
                                    <p></p>
                                    <a href="/contact" className="axil-btn btn-fill-primary btn-large">
                                        Let's Connect
                                    </a>
                                </div>
                                <div className="case-study-counterup">
                                    <div className="single-counterup">
                                        <h2 className="count-number">
                                            <span className="number count" data-count={10}>
                                                10
                                            </span>
                                            <span className="symbol">+</span>
                                        </h2>
                                        <span className="counter-title">Years on the market</span>
                                    </div>
                                    <div className="single-counterup">
                                        <h2 className="count-number">
                                            <span className="number count" data-count={1500}>
                                                1500
                                            </span>
                                            <span className="symbol">+</span>
                                        </h2>
                                        <span className="counter-title">Projects delivered so far</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*=====================================*/}
            {/*=      About  Quality Area Start    =*/}
            {/*=====================================*/}
            <section className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
                <div className="container">
                    <div className="section-heading heading-light-left mb--100">
                        <span className="subtitle">Why should you work with us?</span>
                        <h2 className="title">What Sets Us Apart?</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="about-quality active">
                                <h3 className="sl-number">1</h3>
                                <h5 className="title">Tailored Solutions</h5>
                                <p className="text-justify">We understand that every business is unique, which is why we don't believe in one-size-fits-all solutions. Our team takes the time to understand the specific needs, challenges, and goals of each client, and we tailor our solutions accordingly. Whether it's software development, cybersecurity, cloud migration, or IT strategy, our approach is customized to deliver the best results.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="about-quality">
                                <h3 className="sl-number">2</h3>
                                <h5 className="title">Expertise Across Industries</h5>
                                <p className="text-justify">With our deep expertise across various industries, we bring a unique perspective to every project. Our team comprises seasoned professionals with extensive experience in healthcare, finance, retail, manufacturing, and more. This industry knowledge allows us to anticipate trends, understand regulatory requirements, and offer insights that drive business success.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="about-quality">
                                <h3 className="sl-number">3</h3>
                                <h5 className="title">Cutting-Edge Technology</h5>
                                <p className="text-justify">In the fast-paced world of technology, staying ahead of the curve is crucial. That's why we're committed to leveraging the latest tools, frameworks, and methodologies to deliver innovative solutions. From AI and machine learning to blockchain and IoT, we harness the power of cutting-edge technology to solve complex business challenges and drive digital transformation.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="about-quality">
                                <h3 className="sl-number">4</h3>
                                <h5 className="title">Agile and Collaborative Approach</h5>
                                <p className="text-justify">We believe in collaboration every step of the way. Our agile methodology ensures that our clients are involved in the process from planning to delivery, providing regular updates and soliciting feedback along the way. This collaborative approach fosters transparency, fosters trust, and ensures that the final product meets or exceeds expectations.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="about-quality">
                                <h3 className="sl-number">5</h3>
                                <h5 className="title">Commitment to Customer Success</h5>
                                <p className="text-justify">At the heart of everything we do is a relentless focus on customer success. We measure our success by the success of our clients, and we go above and beyond to ensure their satisfaction. Whether it's providing ongoing support, troubleshooting issues, or helping them scale their operations, we're dedicated to being a trusted partner on their journey to success.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="list-unstyled shape-group-10">
                    <li className="shape shape-1">
                        <img src="../assets/media/others/circle-1.png" alt="Circle" />
                    </li>
                    <li className="shape shape-2">
                        <img src="../assets/media/others/line-3.png" alt="Circle" />
                    </li>
                    <li className="shape shape-3">
                        <img src="../assets/media/others/bubble-5.png" alt="Circle" />
                    </li>
                </ul>
            </section>
            {/*=====================================*/}
            {/*=       About Team  Area Start      =*/}
            {/*=====================================*/}
            <section className="section-padding-equal">
                <div className="container">
                    <div className="row align-items-center">
                        <div
                            className="col-lg-7"
                            data-sal="slide-right"
                            data-sal-duration={800}
                            data-sal-delay={100}
                        >
                            <div className="about-team">
                                <div className="thumbnail">
                                    {/* <img src="../assets/media/about/about-2.png" alt="thumbnail" /> */}
                                    
                                    <Player
                                        autoplay
                                        speed={1.5}
                                        loop
                                        src={consultingAboutAnimation}
                                    
                                    >  
                                        <Controls
                                        visible={false}
                                        buttons={[
                                            "play",
                                            "repeat",
                                            "frame",
                                            "debug",
                                            "snapshot",
                                            "background"
                                        ]}
                                        />
                                    </Player>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-5"
                            data-sal="slide-left"
                            data-sal-duration={800}
                            data-sal-delay={100}
                        >
                            <div className="about-team">
                                <div className="section-heading heading-left">
                                    <span className="subtitle">Our Team</span>
                                    <h2>Empowering Your Business with Expert Consulting!</h2>
                                    <p className="text-justify">
                                    "Empowering Your Business with Cutting-Edge IT Solutions and expert consulting. Our Team at Gammaverse is Dedicated to Delivering Innovative Technology Services and Strategic Consulting to Drive Your Success. Let's Navigate the Digital Landscape Together."
                                    </p>
                                    <a
                                        href="/team"
                                        className="axil-btn btn-large btn-fill-primary"
                                    >
                                        Our Team
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*=====================================*/}
            {/*=       Work Process Area Start     =*/}
            {/*=====================================*/}
            {/* <section className="section section-padding bg-color-light pb--70">
                <div className="container">
                    <div className="section-heading mb--90">
                        <span className="subtitle">Process</span>
                        <h2 className="title">Our Process</h2>
                    </div>
                    <div
                        className="process-work"
                        data-sal="slide-right"
                        data-sal-duration={1000}
                        data-sal-delay={100}
                    >
                        <div className="">
                            <img src={initialImage} alt="Thumbnail" />
                            <Player autoplay speed={1.5} loop src={animation} style={{ height: "500px", width: "500px"}} >  
                                <Controls visible={false}
                                    buttons={[
                                        "play",
                                        "repeat",
                                        "frame",
                                        "debug",
                                        "snapshot",
                                        "background"
                                    ]}
                                />
                            </Player>
                        </div>
                        <div className="content">
                            <span className="subtitle">Step One</span>
                            <h3 className="title">Initial Consultation</h3>
                            <ul>
                                <li>Understand client needs and objectives.</li>
                                <li>Identify pain points and areas for improvement.</li>
                            </ul>
                            <p className="text-justify">
                                At the outset, our team engages in in-depth discussions with clients to comprehend their unique requirements and goals, aiming to gain a comprehensive understanding of their business objectives and challenges.
                            </p>
                        </div>
                    </div>
                    <div
                        className="process-work content-reverse"
                        data-sal="slide-left"
                        data-sal-duration={1000}
                        data-sal-delay={100}
                    >
                        <div className="thumbnail paralax-image">
                            <img src={analysisImage} alt="Thumbnail" />
                        </div>
                        <div className="content">
                            <span className="subtitle">Step Two</span>
                            <h3 className="title">Assessment and Analysis</h3>
                            <ul>
                                <li>Conduct a thorough analysis of the client's current IT infrastructure.</li>
                                <li>Evaluate existing systems, software, and processes.</li>
                                <li>Identify strengths, weaknesses, opportunities, and threats.</li>
                            </ul>
                            <p className="text-justify">Following the initial consultation, we embark on a meticulous evaluation of the client's existing IT landscape, meticulously scrutinizing their systems, processes, and technologies to identify areas for improvement and optimization.</p>
                        </div>
                    </div>
                    <div
                        className="process-work"
                        data-sal="slide-right"
                        data-sal-duration={1000}
                        data-sal-delay={100}
                    >
                        <div className="thumbnail paralax-image">
                            <img src={strategyImage} alt="Thumbnail" />
                        </div>
                        <div className="content">
                            <span className="subtitle">Step Three</span>
                            <h3 className="title">Strategy Development</h3>
                            <ul>
                                <li>Collaborate with the client to develop a tailored IT strategy aligned with business goals.</li>
                                <li>Define objectives, timelines, and key performance indicators (KPIs).</li>
                                <li>Determine budget and resource requirements.</li>
                            </ul>
                            <p className="text-justify">Leveraging insights garnered from the assessment phase, we collaborate closely with clients to craft a tailored IT strategy meticulously aligned with their business objectives, outlining clear goals, timelines, and resource allocations to guide subsequent actions.</p>
                        </div>
                    </div>
                    <div
                        className="process-work content-reverse"
                        data-sal="slide-left"
                        data-sal-duration={1000}
                        data-sal-delay={100}
                    >
                        <div className="thumbnail paralax-image">
                            <img src={solutionImage} alt="Thumbnail" />
                        </div>
                        <div className="content">
                            <span className="subtitle">Step four</span>
                            <h3 className="title">Solution Design</h3>
                            <ul>
                                <li>Design customized IT solutions to address specific client needs.</li>
                                <li>Select appropriate technologies, platforms, and tools.</li>
                                <li>Create implementation plans and architecture diagrams.</li>
                            </ul>
                            <p className="text-justify">With the strategy in place, our expert team meticulously designs bespoke IT solutions, carefully selecting the most suitable technologies and methodologies to address identified challenges and fulfill predefined objectives.</p>
                        </div>
                    </div>
                    <div
                        className="process-work"
                        data-sal="slide-right"
                        data-sal-duration={1000}
                        data-sal-delay={100}
                    >
                        <div className="thumbnail paralax-image">
                            <img src={implementImage} alt="Thumbnail" />
                        </div>
                        <div className="content">
                            <span className="subtitle">Step Five</span>
                            <h3 className="title">Implementation and Integration</h3>
                            <ul>
                                <li>Deploy IT solutions according to the agreed-upon plan.</li>
                                <li>Integrate new systems with existing infrastructure.</li>
                                <li>Ensure minimal disruption to ongoing business operations.</li>
                            </ul>
                            <p className="text-justify">Executing the meticulously crafted solution designs, we deploy new IT solutions seamlessly into the client's existing infrastructure, ensuring smooth integration and minimal disruption to ongoing operations.</p>
                        </div>
                    </div>
                    <div
                        className="process-work content-reverse"
                        data-sal="slide-left"
                        data-sal-duration={1000}
                        data-sal-delay={100}
                    >
                        <div className="thumbnail paralax-image">
                            <img src={testingImage} alt="Thumbnail" />
                        </div>
                        <div className="content">
                            <span className="subtitle">Step Six</span>
                            <h3 className="title">Testing and Quality Assurance:</h3>
                            <ul>
                                <li>Conduct thorough testing of implemented solutions.</li>
                                <li>Identify and resolve any issues or bugs.</li>
                                <li>Ensure reliability, security, and scalability.</li>
                            </ul>
                            <p className="text-justify">Prior to full deployment, our team rigorously tests the implemented solutions, meticulously examining their performance, security, and reliability to identify and rectify any potential issues or vulnerabilities.</p>
                        </div>
                    </div>
                    <div
                        className="process-work"
                        data-sal="slide-right"
                        data-sal-duration={1000}
                        data-sal-delay={100}
                    >
                        <div className="thumbnail paralax-image">
                            <img src={trainingImage} alt="Thumbnail" />
                        </div>
                        <div className="content">
                            <span className="subtitle">Step Seven</span>
                            <h3 className="title">Training and Change Management:</h3>
                            <ul>
                            <li>Provide training sessions for employees to familiarize them with new systems and processes.</li>
                            <li>Develop change management strategies to facilitate smooth adoption of changes.</li>
                            </ul>
                            <p className="text-justify">As part of our comprehensive approach, we provide thorough training to client personnel to facilitate smooth adoption of new systems and processes, complemented by robust change management strategies to navigate organizational transitions effectively.</p>
                        </div>
                    </div>
                    <div
                        className="process-work content-reverse"
                        data-sal="slide-left"
                        data-sal-duration={1000}
                        data-sal-delay={100}
                    >
                        <div className="thumbnail paralax-image">
                            <img src={supportImage} alt="Thumbnail" />
                        </div>
                        <div className="content">
                            <span className="subtitle">Step Eight</span>
                            <h3 className="title">Monitoring and Support</h3>
                            <ul>
                                <li>Implement monitoring tools to track system performance and security.</li>
                                <li>Provide ongoing technical support and troubleshooting.</li>
                                <li>Regularly review and optimize IT systems for efficiency and effectiveness.</li>
                            </ul>
                            <p className="text-justify">Following deployment, we implement robust monitoring mechanisms to track system performance and security, supplemented by dedicated technical support services to promptly address any emerging issues or concerns.</p>
                        </div>
                    </div>
                    <div
                        className="process-work"
                        data-sal="slide-right"
                        data-sal-duration={1000}
                        data-sal-delay={100}
                    >
                        <div className="thumbnail paralax-image">
                            <img src={cicdImage} alt="Thumbnail" />
                        </div>
                        <div className="content">
                            <span className="subtitle">Step Nine</span>
                            <h3 className="title">Continuous Improvement</h3>
                            <ul>
                                <li>Gather feedback from clients and stakeholders.</li>
                                <li>Evaluate the success of implemented solutions against predefined KPIs.</li>
                                <li>Identify areas for further optimization and enhancement.</li>
                                <li>Iteratively improve IT systems and processes to adapt to evolving business needs and technological advancements.</li>
                            </ul>
                            <p className="text-justify">Embracing a philosophy of continual enhancement, we regularly review and refine implemented solutions based on client feedback and evolving business needs, perpetually striving to optimize IT systems and processes for sustained success.</p>
                        </div>
                    </div>
                    <div
                        className="process-work content-reverse"
                        data-sal="slide-left"
                        data-sal-duration={1000}
                        data-sal-delay={100}
                    >
                        <div className="thumbnail paralax-image">
                            <img src={documentationImage} alt="Thumbnail" />
                        </div>
                        <div className="content">
                            <span className="subtitle">Step 10</span>
                            <h3 className="title">Documentation and Knowledge Transfer</h3>
                            <ul>
                                <li>Document all aspects of the project, including designs, configurations, and procedures.</li>
                                <li>Transfer knowledge to the client's IT team for long-term maintenance and management.</li>
                            </ul>
                            <p className="text-justify">Finally, we meticulously document all aspects of the project, including designs, configurations, and procedures, facilitating seamless knowledge transfer to empower the client's internal IT team for long-term maintenance and management.</p>
                        </div>
                    </div>
                </div>
                <ul className="shape-group-17 list-unstyled">
                    <li className="shape shape-1">
                        <img src="../assets/media/others/bubble-24.png" alt="Bubble" />
                    </li>
                    <li className="shape shape-2">
                        <img src="../assets/media/others/bubble-23.png" alt="Bubble" />
                    </li>
                    <li className="shape shape-3">
                        <img src="../assets/media/others/line-4.png" alt="Line" />
                    </li>
                    <li className="shape shape-4">
                        <img src="../assets/media/others/line-5.png" alt="Line" />
                    </li>
                    <li className="shape shape-5">
                        <img src="../assets/media/others/line-4.png" alt="Line" />
                    </li>
                    <li className="shape shape-6">
                        <img src="../assets/media/others/line-5.png" alt="Line" />
                    </li>
                </ul>
            </section> */}
            {/*=====================================*/}
            {/*=     Call To Action Area Start     =*/}
            {/*=====================================*/}
            
        </>

    );
}