import { Route, Link, Routes, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Page404 from "./pages/Page404";
import Blogs from "./pages/Blogs";
import Product from "./pages/Product";
import ProductEngineering from "./pages/ProductEngineering";
import CaseStudy from "./pages/CaseStudy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import Portfolio from "./pages/Portfolio";
import ITConsulting from "./pages/ITConsulting";
import Services from "./pages/Services";
import Service from "./pages/Service";
import Test from "./pages/Test"
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Header from "./components/Header";
import CTA from "./components/CTA";
import React, { useEffect } from "react";
import sal from 'sal.js';
function App() {
  sal({
    threshold: 0.1,
    once: false,
  });
  useEffect(() => {
    sal();
  }, []);
  return (
    <>
      <BrowserRouter>
        <Header />
        <div id="main-wrapper" className="main-wrapper">
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/services" element={<Services />} />
            <Route path="/service/:slug" element={<Service />} />
            <Route path="/product" element={<Product />} />
            <Route path="/product-engineering" element={<ProductEngineering />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/case-study" element={<CaseStudy />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/it-consulting" element={<ITConsulting />} />
            <Route path="/test" element={<Test />} />
            <Route path="*" element={< Page404 />} />
          </Routes>
          {/* <CTA /> */}
          <Footer />
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
