import ContactInfo from "./../data/contact-data.json";

export default function TermsOfUse() {
    const phoneOne = "tel:"+ContactInfo.phone1
    const phoneTwo = "tel:"+ContactInfo.phone2
    const emailInfo = "mailto:"+ContactInfo["email-info"]
    const emailSupport = "mailto:"+ContactInfo["email-support"]
    return (
        <>
            {/*=====================================*/}
            {/*=       Breadcrumb Area Start       =*/}
            {/*=====================================*/}
            <div className="breadcrum-area">
                <div className="container">
                    <div className="breadcrumb">
                        <ul className="list-unstyled">
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li className="active">Terms of Use</li>
                        </ul>
                        <h1 className="title h2">Terms of Use</h1>
                    </div>
                </div>
                <ul className="shape-group-8 list-unstyled">
                    <li
                        className="shape shape-1"
                        data-sal="slide-right"
                        data-sal-duration={500}
                        data-sal-delay={100}
                    >
                        <img src="assets/media/others/bubble-9.png" alt="Bubble" />
                    </li>
                    <li
                        className="shape shape-2"
                        data-sal="slide-left"
                        data-sal-duration={500}
                        data-sal-delay={200}
                    >
                        <img src="assets/media/others/bubble-11.png" alt="Bubble" />
                    </li>
                    <li
                        className="shape shape-3"
                        data-sal="slide-up"
                        data-sal-duration={500}
                        data-sal-delay={300}
                    >
                        <img src="assets/media/others/line-4.png" alt="Line" />
                    </li>
                </ul>
            </div>
            {/*=====================================*/}
            {/*=    Privacy Policy Area Start      =*/}
            {/*=====================================*/}
            <section className="section-padding privacy-policy-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="privacy-policy-content">
                                <h6>Welcome to Gammaverse Private Limited!
                                </h6>
                                <p aligin="justify">
                                By accessing or using our website, services, or solutions, you agree to comply with and be bound by these Terms of Use. Please read them carefully before proceeding.
                                </p>
                                <h4>1. Acceptance of Terms</h4>
                                <p aligin="justify">
                                By using our website or services, you agree to these Terms of Use. If you do not agree, please refrain from accessing our website or using our services.
                                </p>
                                <h4>2. Use of Services</h4>
                                <p aligin="justify">
                                    <ul>
                                        <li><strong>Authorized Use:</strong> You may use our services only for lawful purposes and in compliance with applicable laws and regulations.</li>
                                        <li><strong>Prohibited Actions:</strong>You may not misuse, reproduce, or distribute any content or materials provided by Gammaverse without prior written permission.</li>
                                    </ul>
                                </p>
                                <h4>3. Intellectual Property</h4>
                                <p aligin="justify">
                                All content, trademarks, logos, and intellectual property on this website are owned by Gammaverse Private Limited. Unauthorized use is strictly prohibited.
                                </p>
                                <h4>4. Limitation of Liability</h4>
                                <p aligin="justify">
                                    <ul>
                                        <li>Gammaverse Private Limited is not liable for any indirect, incidental, or consequential damages arising from your use of our website or services.</li>
                                        <li>We strive for accuracy but do not guarantee the completeness or reliability of our website’s content.</li>
                                    </ul>                                
                                </p>
                                <h4>5. Privacy Policy</h4>
                                <p aligin="justify">
                                Your use of our services is subject to our Privacy Policy, which governs the collection and use of your personal data.
                                </p>
                                <h4>6. Termination of Access</h4>
                                <p aligin="justify">
                                We reserve the right to terminate or restrict access to our website or services at any time without notice for violations of these Terms of Use.
                                </p>
                                <h4>7. Changes to Terms</h4>
                                <p aligin="justify">
                                Gammaverse Private Limited reserves the right to update or modify these Terms of Use at any time. Continued use of our services after changes constitutes your acceptance of the revised terms.
                                </p>
                                <h4>8. Governing Law</h4>
                                <p aligin="justify">
                                These Terms of Use are governed by the laws of India, and any disputes will be resolved under the jurisdiction of the courts in Mumbai.
                                </p>
                                <h4>9. Contact Us</h4>
                                <p aligin="justify">
                                For any questions or concerns about these Terms of Use, please contact us at:<br/>
                                Email: <a href={emailSupport}>{ContactInfo["email-support"]}</a> <br/>
                                Phone: <a href={phoneOne}>{ContactInfo.phone1}</a>, <a href={phoneTwo}>{ContactInfo.phone2}</a><br/><br/>

                                Thank you for choosing Gammaverse Private Limited!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
}