import Coming from "../components/Coming";
import ContactInfo from "./../data/contact-data.json";

export default function PrivacyPolicy() {
    const phoneOne = "tel:"+ContactInfo.phone1
    const phoneTwo = "tel:"+ContactInfo.phone2
    const emailInfo = "mailto:"+ContactInfo["email-info"]
    const emailSupport = "mailto:"+ContactInfo["email-support"]

    const today = new Date();

    const getDayWithSuffix = (day) => {
      if (day > 3 && day < 21) return `${day}th`;
      const suffixes = ["th", "st", "nd", "rd"];
      const remainder = day % 10;
      return `${day}${suffixes[remainder] || "th"}`;
    };
  
    const formattedDate = `${getDayWithSuffix(today.getDate())} ${today.toLocaleString(
      "en-US",
      { month: "short" }
    )}' ${today.getFullYear()}`;
  
    return (
        <>
            {/*=====================================*/}
            {/*=       Breadcrumb Area Start       =*/}
            {/*=====================================*/}
            <div className="breadcrum-area">
                <div className="container">
                    <div className="breadcrumb">
                        <ul className="list-unstyled">
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li className="active">Privacy Policy</li>
                        </ul>
                        <h1 className="title h2">Privacy Policy</h1>
                    </div>
                </div>
                <ul className="shape-group-8 list-unstyled">
                    <li
                        className="shape shape-1"
                        data-sal="slide-right"
                        data-sal-duration={500}
                        data-sal-delay={100}
                    >
                        <img src="assets/media/others/bubble-9.png" alt="Bubble" />
                    </li>
                    <li
                        className="shape shape-2"
                        data-sal="slide-left"
                        data-sal-duration={500}
                        data-sal-delay={200}
                    >
                        <img src="assets/media/others/bubble-11.png" alt="Bubble" />
                    </li>
                    <li
                        className="shape shape-3"
                        data-sal="slide-up"
                        data-sal-duration={500}
                        data-sal-delay={300}
                    >
                        <img src="assets/media/others/line-4.png" alt="Line" />
                    </li>
                </ul>
            </div>
            {/*=====================================*/}
            {/*=    Privacy Policy Area Start      =*/}
            {/*=====================================*/}
            <section className="section-padding privacy-policy-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="privacy-policy-content">
                                <h6>Effective Date: {formattedDate + ""}</h6>
                                <p aligin="justify">
                                At <strong>Gammaverse</strong>, your privacy is our priority. This Privacy Policy explains how we collect, use, and protect your information when you use our website and services. By accessing our website or services, you agree to the terms outlined below.
                                </p>
                                <h4>1. Information We Collect</h4>
                                <p aligin="justify">
                                We collect the following types of information:
                                <ul>
                                    <li><strong>Personal Information:</strong> Name, email address, phone number, company details, and other details provided voluntarily.
                                    </li>
                                    <li><strong>Non-Personal Information:</strong> Browser type, IP address, device information, and website usage data collected automatically through cookies and analytics tools.</li>
                                </ul>
                                </p>
                                <h4>2. How We Use Your Information</h4>
                                <p aligin="justify">
                                The information we collect is used to:
                                    <ul>
                                        <li>Provide and improve our services.</li>
                                        <li>Respond to inquiries and provide customer support.</li>
                                        <li>Send updates, newsletters, or marketing communications (only if you opt-in).</li>
                                        <li>Analyze website performance and user behavior.</li>
                                        <li>Comply with legal obligations.</li>
                                    </ul>
                                </p>
                                <h4>3. Sharing Your Information</h4>
                                <p aligin="justify">
                                We do not sell or rent your personal information to third parties. However, we may share your information with:
                                <ul>
                                    <li><strong>Service Providers:</strong> Trusted partners who assist in delivering our services (e.g., hosting, payment processing).</li>
                                    <li><strong>Legal Authorities: </strong>When required to comply with applicable laws or legal processes.</li>
                                </ul>
                                </p>
                                <h4>4. Data Protection</h4>
                                <p aligin="justify">
                                We employ industry-standard security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security.                                
                                </p>
                                <h4>5. Cookies and Tracking Technologies</h4>
                                <p aligin="justify">
                                Our website uses cookies to enhance user experience and analyze website traffic. You can manage your cookie preferences through your browser settings. Note that disabling cookies may affect some website functionalities.
                                </p>
                                <h4>6. Your Rights</h4>
                                <p aligin="justify">
                                You have the right to:
                                <ul>
                                    <li>Access and review the personal information we hold about you.</li>
                                    <li>Request corrections or updates to your information.</li>
                                    <li>Withdraw consent for specific data uses, including marketing communications.</li>
                                    <li>Request deletion of your personal data, subject to legal requirements.</li>
                                </ul>
                                </p>
                                <h4>7. Third-Party Links</h4>
                                <p aligin="justify">
                                Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of those websites. Please review their privacy policies before providing any personal information.
                                </p>
                                <h4>8. Children's Privacy</h4>
                                <p aligin="justify">
                                Our services are not intended for individuals under the age of 18. We do not knowingly collect personal information from minors.
                                </p>
                                <h4>9. Changes to This Policy</h4>
                                <p aligin="justify">
                                We reserve the right to update or modify this Privacy Policy at any time. Changes will be effective immediately upon posting on our website. We encourage you to review this policy periodically.
                                </p>
                                <h4>10. Contact Us</h4>
                                <p aligin="justify">
                                For questions or concerns regarding this Privacy Policy, please contact us:<br/>
                                Email: <a href={emailSupport}>{ContactInfo["email-support"]}</a> <br/>
                                Phone: <a href={phoneOne}>{ContactInfo.phone1}</a>, <a href={phoneTwo}>{ContactInfo.phone2}</a><br/><br/>

                                Thank you for choosing Gammaverse Private Limited!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
}